import React, { useState, useEffect, useRef, useContext } from "react"
import { useCookies } from 'react-cookie';
import axios from "axios"

import { Link } from "~components/linkWithPreviousUrl/linkWithPreviousUrl"
import { getAuth, sendPasswordResetEmail } from "firebase/auth"
import { useForm } from "react-hook-form"
import { motion, AnimatePresence } from "framer-motion"
import classnames from "classnames"
import isEqual from "lodash.isequal"

/* Import Global Context(s) */
import TokenContext from "~context/token"
import AccountContext from "~context/account"

import ApplicationForm from '~components/pages/profile/applicationForm'

import { navigate } from "gatsby"

/* Import Global Hook(s) */
import usePreviousValue from "~hooks/usePreviousValue"

/* Import Page Component(s) */
import ProfilePage from "~components/pages/profile/profilePage"

/* Import Global Animation(s) */
import elementTransitionAnimation from "~animations/elementTransition"

/* Import Local Style(s) */
import "./reset.scss"

const ResetPassword = ({ location }) => {
  const [feedback, setFeedback] = useState()
  const formRef = useRef()
  const {
    register,
    handleSubmit,
    watch,
    formState: { isValid },
  } = useForm({ mode: "onChange" })

  const [cookies, setCookie] = useCookies(['sessionToken']);

  const [tokenString, setToken] = useContext(TokenContext);
  const [accountInfo, setAccountInfo] = useContext(AccountContext);

  const allFieldsState = watch()
  const previousAllFieldsState = usePreviousValue(allFieldsState)

  const queryParams = new URLSearchParams(location.search);

  const isNewAccount = queryParams.get('new')
  /* 
   * this means the account filled out an application for the first time.
   * all that is necessary is that the user sets a new password
   *
   * if not new, then we need to fill out a "refresher" from. 
   * 
   */
  const emailPath = queryParams.get('email')
  const accountToken = queryParams.get('token')

  const pageTitle = isNewAccount ? 'VIP Setup' : 'VIP Reset'
  const pageSubmission = isNewAccount ? 'Submit' : 'Reset'


  useEffect(() => {

    if (feedback) {
      if (!isEqual(allFieldsState, previousAllFieldsState)) {
        setFeedback()
      }
    }
  }, [allFieldsState, previousAllFieldsState, feedback, setFeedback])

  const onSubmit = ({ password, password_confirmation }) => {
    
    let params = new URLSearchParams(location.search);

    console.log(params.get('email'), params.get('token'), params.get('new'))

    const isNew = params.get('new') // get token from path
    const email = params.get('email') // get email from path
    const token = params.get('token') // get whether there was a password prior...

    // get password and password_confirmation from form

    if (!email || !token) {
      return
    }

    const data = { email, token, password, password_confirmation }
    const path = '/vips/auth/reset-password'

    console.log(data)


    if(password !== password_confirmation){
      setFeedback('Your proposed password does not match its confirmation.')
      return
    }


    setFeedback('Submitting reset...')
    axios
    .post("/.netlify/functions/vipUsers", {
      contents: data,
      path: path
    })
    .then(res => {

      console.log(res)

      if(res.data.data.errors !== undefined){
        setFeedback(res.data.data.message)
        setTimeout(() => {
          setFeedback(false)
        }, 9000)
      }if(res.data.status == 422){
        setFeedback('There was an error processing your request. Please try again.')
        setTimeout(() => {
          setFeedback(false)
        }, 9000)
      }else{
        // automate login:
        
        setFeedback('Your submission was successfully processed. Logging you to profile page...')
        const accountInfo = { email, password }

        axios
        .post("/.netlify/functions/vipUsers", {
          contents: accountInfo,
          path: '/vips/auth/login'
        })
        .then(loginRes => {
          console.log('successful request: ')
          console.log(loginRes)
          console.log(loginRes.data)

          if(loginRes.data.status === 422){
            console.log(loginRes.data.data.errors)
            setFeedback(loginRes.data.data.message)
            setTimeout(() => {
              setFeedback(false)
            }, 5000)
            return
          }

          setToken(loginRes.data.data.token)
          setCookie('sessionToken', loginRes.data.data.token, { path: '/' })

          setFeedback('Login Successful. Redirecting...')


          axios
          .post('/.netlify/functions/vipUsers', {
            contents: {token: loginRes.data.data.token},
            method: 'with_auth',
            path: '/vips/auth/me'
          })
          .then(result => {

            console.log(result)
            if(result.data.data !== undefined && result.data.data !== null){
              setAccountInfo(result.data.data)

              if (typeof window !== `undefined`) {
                
                
                if(isNewAccount){
                  navigate("/vip/profile?new=1&email=" + emailPath)
                }else{
                  navigate("/vip/profile")
                }

                
              }

            }else{
              console.log('unauthorized')
              setFeedback('There was an error logging in.')
            }
            

          })

        })
        .catch(e => {
          console.error(e)

          setFeedback('There was an error with your request.')
        })
      }



    })
    .catch(e => {
      setFeedback('There was an error processing your request. Please try again.')
      console.log(e)
      console.error(e)
    })

  }

  return (
    <ProfilePage title={pageTitle} header={pageTitle} location={location}>
      {<form onSubmit={handleSubmit(onSubmit)} className="log-in vip-reset" ref={formRef}>
        <input
          type="password"
          name="password"
          placeholder="Password"
          {...register("password", { required: true })}
        />
        <input
          type="password"
          name="password_confirmation"
          placeholder="Confirm Password"
          {...register("password_confirmation", { required: true })}
        />
        <input
          className={classnames('col col--full apply_submit blue_btn')}
          type="submit"
          placeholder={pageSubmission}
          value={pageSubmission}
          title={pageSubmission}
        />

        {feedback && <p className="mb--2 font-size--small">{feedback}</p>}

      </form> }


      {/*!isNewAccount && <ApplicationForm 
          token={accountToken} 
          passwordField={true} 
          username={emailPath}
          passwordPath={'/vips/auth/reset-password'}
          path={'/vips/profile'}
        />*/}

    </ProfilePage>
  )
}

export default ResetPassword
